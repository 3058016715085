<template>
  <div v-if="editor" class="control-block">
    <Select
        style="width: 160px;"
        :array="headings"
        placeholder="Заголовок"
        @select="toggleHeading"
        :pre-selected="headingLevel"
    />

    <button
        @click="
          editor
            .chain()
            .focus()
            .undo()
            .run()
        "
    >
      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.70711 1.70711C6.09763 1.31658 6.09763 0.683417 5.70711 0.292893C5.31658 -0.0976311 4.68342 -0.0976311 4.29289 0.292893L0.292893 4.29289C-0.0976311 4.68342 -0.0976311 5.31658 0.292893 5.70711L4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711C6.09763 9.31658 6.09763 8.68342 5.70711 8.29289L3.41421 6H11C12.6569 6 14 7.34315 14 9C14 10.6569 12.6569 12 11 12H10C9.44771 12 9 12.4477 9 13C9 13.5523 9.44771 14 10 14H11C13.7614 14 16 11.7614 16 9C16 6.23858 13.7614 4 11 4H3.41421L5.70711 1.70711Z"
            fill="#113079"/>
      </svg>
    </button>
    <button
        @click="
          editor
            .chain()
            .focus()
            .redo()
            .run()
        "
    >
      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.2929 1.70711C9.90237 1.31658 9.90237 0.683417 10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893L15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.7071 9.70711C11.3166 10.0976 10.6834 10.0976 10.2929 9.70711C9.90237 9.31658 9.90237 8.68342 10.2929 8.29289L12.5858 6H5C3.34315 6 2 7.34315 2 9C2 10.6569 3.34315 12 5 12H6C6.55229 12 7 12.4477 7 13C7 13.5523 6.55229 14 6 14H5C2.23858 14 0 11.7614 0 9C0 6.23858 2.23858 4 5 4H12.5858L10.2929 1.70711Z"
            fill="#113079"/>
      </svg>
    </button>
    <div class="divider"/>
    <button
        @click="
          editor
            .chain()
            .focus()
            .toggleBold()
            .run()
        "
        :class="{ 'is-active': editor.isActive('bold') }"
    >
      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.5 0C0.947715 0 0.5 0.447715 0.5 1V15C0.5 15.5523 0.947715 16 1.5 16H9C11.4853 16 13.5 13.9853 13.5 11.5C13.5 9.83702 12.5979 8.38474 11.2564 7.60575C12.0269 6.79814 12.5 5.70432 12.5 4.5C12.5 2.01472 10.4853 0 8 0H1.5ZM8 7C9.38071 7 10.5 5.88071 10.5 4.5C10.5 3.11929 9.38071 2 8 2H2.5V7H8ZM2.5 9V14H9C10.3807 14 11.5 12.8807 11.5 11.5C11.5 10.1193 10.3807 9 9 9H2.5Z"
        />
      </svg>
    </button>
    <button
        @click="
          editor
            .chain()
            .focus()
            .toggleItalic()
            .run()
        "
        :class="{ 'is-active': editor.isActive('italic') }"
    >
      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.67427 2.00026L4.2457 14.0003H1C0.447715 14.0003 0 14.448 0 15.0003C0 15.5525 0.447715 16.0003 1 16.0003H4.97788C4.993 16.0006 5.00808 16.0006 5.02311 16.0003H9C9.55229 16.0003 10 15.5525 10 15.0003C10 14.448 9.55229 14.0003 9 14.0003H6.32573L9.7543 2.00026L13 2.00026C13.5523 2.00026 14 1.55254 14 1.00026C14 0.447973 13.5523 0.00025771 13 0.000257733L9.02213 0.000257896C9.00701 -8.68905e-05 8.99192 -8.50395e-05 8.97688 0.000257898L5 0.000258061C4.44772 0.000258083 4 0.447973 4 1.00026C4 1.55254 4.44772 2.00026 5 2.00026L7.67427 2.00026Z"
        />
      </svg>
    </button>
    <button
        @click="
          editor
            .chain()
            .focus()
            .toggleStrike()
            .run()
        "
        :class="{ 'is-active': editor.isActive('strike') }"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.39723 0C10.4773 0 12.0511 0.931183 13.108 2.14708C13.3251 2.36809 13.4629 2.6508 13.4629 2.9027C13.4629 3.44057 13.048 3.93792 12.4909 3.93792C12.1463 3.93792 11.8394 3.73989 11.5998 3.50614C10.7339 2.58047 9.6498 2.03983 8.30558 2.03983C7.56567 2.03983 6.88239 2.20793 6.39363 2.54235C5.91787 2.86787 5.61174 3.35865 5.61174 4.06605C5.61174 4.37388 5.67173 4.63198 5.78859 4.85789C5.99985 5.26634 5.81614 6 5.35628 6C4.44216 6 3.52086 5.52336 3.41786 4.61506C3.4012 4.46812 3.39262 4.31583 3.39262 4.15789C3.39262 2.81644 4.00034 1.76597 4.93642 1.05932C5.86513 0.358228 7.10833 0 8.39723 0Z"
        />
        <path
            d="M11.7719 10C12.6832 10 13.5573 10.548 13.5832 11.4589C13.5845 11.5046 13.5851 11.5506 13.5851 11.5972C13.5851 13.0577 12.9497 14.1707 11.9686 14.9102C10.9963 15.643 9.69894 16 8.36668 16C5.87895 16 3.90078 14.7529 2.69033 13.0959C2.5484 12.9166 2.41504 12.6699 2.41504 12.3932C2.41504 12.102 2.54774 11.8373 2.73579 11.6488C2.92383 11.4604 3.18809 11.3273 3.47877 11.3273C3.77922 11.3273 4.07843 11.4569 4.28973 11.711L4.29448 11.7169C5.41835 13.1395 6.7144 13.9602 8.36668 13.9602C9.20945 13.9602 9.97607 13.7556 10.5229 13.3685C11.0591 12.989 11.3966 12.4276 11.3966 11.6584C11.3966 11.3963 11.358 11.1663 11.2854 10.9607C11.1527 10.5847 11.3732 10 11.7719 10Z"
        />
        <path
            d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H15C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7H1Z"
        />
      </svg>
    </button>
    <button
        @click="
          editor
            .chain()
            .focus()
            .toggleCode()
            .run()
        "
        :class="{ 'is-active': editor.isActive('code') }"
    >
      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.2929 8.29289C9.90237 8.68342 9.90237 9.31658 10.2929 9.70711C10.6834 10.0976 11.3166 10.0976 11.7071 9.70711L15.7071 5.70711C16.0976 5.31658 16.0976 4.68342 15.7071 4.29289L11.7071 0.292894C11.3166 -0.0976312 10.6834 -0.0976312 10.2929 0.292894C9.90237 0.683417 9.90237 1.31658 10.2929 1.70711L13.5858 5L10.2929 8.29289Z"
        />
        <path
            d="M5.70711 1.70711C6.09763 1.31658 6.09763 0.683418 5.70711 0.292894C5.31658 -0.0976307 4.68342 -0.0976307 4.29289 0.292894L0.292893 4.29289C-0.0976311 4.68342 -0.0976311 5.31658 0.292893 5.70711L4.29289 9.70711C4.68342 10.0976 5.31658 10.0976 5.70711 9.70711C6.09763 9.31658 6.09763 8.68342 5.70711 8.29289L2.41421 5L5.70711 1.70711Z"
        />
      </svg>
    </button>
    <div class="divider"/>
    <button
        @click="
          editor
            .chain()
            .focus()
            .toggleBulletList()
            .run()
        "
        :class="{ 'is-active': editor.isActive('bulletList') }"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
              d="M2 7C3.10457 7 4 6.10457 4 5C4 3.89543 3.10457 3 2 3C0.89543 3 0 3.89543 0 5C0 6.10457 0.89543 7 2 7Z"
          />
          <path
              d="M7 4C6.44772 4 6 4.44772 6 5C6 5.55228 6.44772 6 7 6H15C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4H7Z"
          />
          <path
              d="M7 8C6.44772 8 6 8.44772 6 9C6 9.55228 6.44772 10 7 10H15C15.5523 10 16 9.55228 16 9C16 8.44772 15.5523 8 15 8H7Z"
          />
          <path
              d="M6 13C6 12.4477 6.44772 12 7 12L15 12C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14L7 14C6.44772 14 6 13.5523 6 13Z"
          />
        </g>
      </svg>
    </button>
    <button
        @click="
          editor
            .chain()
            .focus()
            .toggleOrderedList()
            .run()
        "
        :class="{ 'is-active': editor.isActive('orderedList') }"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path
              d="M4 8.00006V2.00006C4 1.44778 3.55228 1 3 1C2.51923 1 2.25738 1.32161 2.00889 1.6766C1.90448 1.82577 1.75471 2.03545 1.575 2.27506C1.21039 2.76121 0.743388 3.34246 0.292893 3.79295C-0.0976311 4.18348 -0.0976311 4.81664 0.292893 5.20717C0.683417 5.59769 1.31658 5.59769 1.70711 5.20717C1.80552 5.10876 1.9034 5.00652 2 4.90203V8.00006C2 8.55235 2.44772 9.00006 3 9.00006C3.55228 9.00006 4 8.55235 4 8.00006Z"
          />
          <path
              d="M7 4C6.44772 4 6 4.44772 6 5C6 5.55228 6.44772 6 7 6H15C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4H7Z"
          />
          <path
              d="M7 8C6.44772 8 6 8.44771 6 9C6 9.55229 6.44772 10 7 10H15C15.5523 10 16 9.55229 16 9C16 8.44771 15.5523 8 15 8H7Z"
          />
          <path
              d="M6 13C6 12.4477 6.44772 12 7 12L15 12C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14L7 14C6.44772 14 6 13.5523 6 13Z"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="16" height="16" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </button>
    <div class="divider"/>
    <div class="dropdown">
      <button class="dropbtn">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.72">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.0001 3.2C7.1201 3.2 6.4001 2.48 6.4001 1.6C6.4001 0.72 7.1201 0 8.0001 0C8.8801 0 9.6001 0.72 9.6001 1.6C9.6001 2.48 8.8801 3.2 8.0001 3.2ZM8.0001 6.4C8.8801 6.4 9.6001 7.12 9.6001 8C9.6001 8.88 8.8801 9.6 8.0001 9.6C7.1201 9.6 6.4001 8.88 6.4001 8C6.4001 7.12 7.1201 6.4 8.0001 6.4ZM9.6001 14.4C9.6001 13.52 8.8801 12.8 8.0001 12.8C7.1201 12.8 6.4001 13.52 6.4001 14.4C6.4001 15.28 7.1201 16 8.0001 16C8.8801 16 9.6001 15.28 9.6001 14.4Z"
                  fill="#113079"/>
          </g>
        </svg>
      </button>
      <div class="dropdown-content">
        <button
            @click="
          editor
            .chain()
            .focus()
            .toggleBlockquote()
            .run()
        "
            :class="{ 'is-active': editor.isActive('blockquote') }"
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 4.1525C11 3.23258 10.3789 2.45771 9.53319 2.22436C9.60887 1.7562 9.74879 1.29745 10.0075 0.705011C10.2081 0.245374 9.72555 -0.20371 9.32501 0.0981322C7.20669 1.69447 7.00221 3.0887 7.00007 4.13555L7 4.1525C7 5.25707 7.89543 6.1525 9 6.1525C10.1046 6.1525 11 5.25707 11 4.1525Z"
            />
            <path
                d="M16 4.15275C16 3.23282 15.3789 2.45795 14.5332 2.2246C14.6089 1.75645 14.7488 1.2977 15.0075 0.705255C15.2081 0.245618 14.7255 -0.203465 14.325 0.0983763C12.2067 1.69472 12.0022 3.08894 12.0001 4.13579L12 4.15275C12 5.25732 12.8954 6.15275 14 6.15275C15.1046 6.15275 16 5.25732 16 4.15275Z"
            />
            <path
                d="M4 11.8474L3.99993 11.8644C3.99779 12.9112 3.79331 14.3054 1.67499 15.9018C1.27445 16.2036 0.791855 15.7545 0.992538 15.2949C1.25121 14.7025 1.39113 14.2437 1.46681 13.7756C0.621088 13.5422 0 12.7673 0 11.8474C0 10.7428 0.895431 9.84741 2 9.84741C3.10457 9.84741 4 10.7428 4 11.8474Z"
            />
            <path
                d="M8.99993 11.8644L9 11.8474C9 10.7428 8.10457 9.84741 7 9.84741C5.89543 9.84741 5 10.7428 5 11.8474C5 12.7673 5.62109 13.5422 6.46681 13.7756C6.39113 14.2437 6.25121 14.7025 5.99254 15.2949C5.79185 15.7545 6.27445 16.2036 6.67499 15.9018C8.79331 14.3054 8.99779 12.9112 8.99993 11.8644Z"
            />
          </svg>
        </button>
        <button
            @click="
          editor
            .chain()
            .focus()
            .setHorizontalRule()
            .run()
        "
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.916 0C2.04 0 1.272 0.228 0.768 0.636C0.48 0.864 0.324 1.14 0.324 1.44C0.324 1.908 0.696 2.28 1.188 2.28C1.368 2.28 1.5 2.232 1.74 2.076C2.052 1.872 2.352 1.776 2.688 1.776C3.276 1.776 3.612 2.052 3.612 2.544V2.64H2.352C0.876 2.64 0 3.312 0 4.44C0 5.544 0.84 6.288 2.076 6.288C2.748 6.288 3.336 6.072 3.756 5.688C3.912 6.048 4.26 6.276 4.68 6.276C5.256 6.276 5.676 5.844 5.676 5.256V2.664C5.676 0.96 4.692 0 2.916 0ZM2.7 4.824C2.316 4.824 2.088 4.656 2.088 4.356C2.088 4.068 2.292 3.9 2.64 3.9H3.612V3.948C3.612 4.476 3.24 4.824 2.7 4.824Z"
                  fill="#3D75E4"/>
            <path
                d="M0 8C0 7.44772 0.447715 7 1 7L15 7C15.5523 7 16 7.44772 16 8C16 8.55229 15.5523 9 15 9L1 9C0.447715 9 0 8.55229 0 8Z"
                fill="#3D75E4"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.54399 11.752C5.54399 12.16 5.39999 12.52 5.12399 12.796C5.67599 13.072 5.97599 13.54 5.97599 14.152C5.97599 15.22 5.06399 16 3.80399 16H1.40399C0.803985 16 0.359985 15.556 0.359985 14.956V11.044C0.359985 10.444 0.803985 10 1.40399 10H3.28799C4.63199 10 5.54399 10.708 5.54399 11.752ZM3.20399 11.608H2.30399V12.244H3.20399C3.49199 12.244 3.64799 12.136 3.64799 11.92C3.64799 11.704 3.51599 11.608 3.20399 11.608ZM2.30399 14.368H3.62399C3.91199 14.368 4.09199 14.224 4.09199 13.984C4.09199 13.744 3.91199 13.6 3.62399 13.6H2.30399V14.368Z"
                  fill="#3D75E4"/>
          </svg>
        </button>
      </div>
    </div>
    <button
        @click="
          editor
            .chain()
            .focus()
            .unsetAllMarks()
            .run()
        "
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M7.25515 0.605518C8.05952 -0.201841 9.36521 -0.201838 10.1696 0.605518L12.9215 3.36763C13.4077 3.85563 13.6179 4.55406 13.4834 5.22888L12.8771 8.27144C12.7977 8.6701 12.6024 9.03673 12.3152 9.32495L7.65745 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H5C4.97378 16 4.9478 15.999 4.92208 15.997C4.35816 16.0274 3.78414 15.8265 3.35369 15.3945L0.601778 12.6324C0.115582 12.1444 -0.0945934 11.4459 0.0398724 10.7711L0.646137 7.72856C0.725572 7.3299 0.920889 6.96327 1.20804 6.67505L7.25515 0.605518ZM8.75276 2.01711C8.73002 1.9943 8.69471 1.9943 8.67198 2.01711L4.82263 5.88073L8.88057 9.93867L10.8984 7.91336C10.9069 7.90481 10.9131 7.89349 10.9157 7.8806L11.522 4.83804C11.5264 4.8159 11.5192 4.79382 11.5047 4.77923L8.75276 2.01711ZM2.62487 8.08664L3.41103 7.29757L7.46897 11.3555L4.85129 13.9829C4.82856 14.0057 4.79325 14.0057 4.77052 13.9829L2.01861 11.2208C2.00407 11.2062 1.9969 11.1841 2.00131 11.162L2.60758 8.1194C2.61014 8.10651 2.61636 8.09519 2.62487 8.08664Z"
              fill="#3D75E4"/>
      </svg>
    </button>
  </div>
</template>
<script>
export default {
  name: 'editor-controls',
  props: {
    editor: {required: true},
  },
  computed: {
    headingLevel() {
      let selectedLevel = this.editor?.getNodeAttributes('heading').level;
      if (selectedLevel) {
        return selectedLevel;
      }
      return null;
    },
  },
  data() {
    return {
      headings: [
        {name: 'h1', id: 1},
        {name: 'h2', id: 2},
        {name: 'h3', id: 3},
        {name: 'h4', id: 4},
        {name: 'h5', id: 5},
        {name: 'h6', id: 6},
      ],
    };
  },
  methods: {
    toggleHeading(level) {
      if (!level) {
        this.editor.chain().focus().toggleHeading({level: this.headingLevel}).run();
        return;
      }
      this.editor.chain().focus().toggleHeading({level: level}).run();
    },
  },
};
</script>
<style scoped lang="scss">
.control-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 8px 0;
  max-width: 618px;

  .divider {
    width: 1px;
    height: 32px;
    background: #E4EDFB;
    border-radius: 1px;
  }

  .is-active {
    background: #3d75e4;

    svg path {
      fill: #FFF !important;
    }
  }

  .select {
    margin-bottom: 0 !important;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 12px;
    font-weight: bold;
    margin-right: 8px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    background: none;

    &:not(.is-active):hover {
      background: var(--main-color-trans-light);

      svg path {
        fill: #3D75E4 !important;
      }

    }

    svg path {
      fill: #113079 !important;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
  }

  /* Change color of dropdown links on hover */

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

}
</style>
